import { graphql } from "gatsby";
import * as React from "react";
import Update from "../components/commons/update";
import Layout from "../components/layout/layout";
import { parseStory } from "../components/utils";

// markup
const Updates = ({ data }) => {
  const upds = data.upds.nodes
    .map(parseStory)
    .sort((a, b) => b.content.date - a.content.date);
  console.log(upds);
  return (
    <Layout>
      <div className="py-6 md:py-12 as-cont">
        <h1 className="as-h1">Updates</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-10">
          {(upds ?? []).map((upd) => (
            <Update upd={upd} key={upd.slug} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    upds: allStoryblokEntry(filter: { field_component: { eq: "update" } }) {
      nodes {
        slug
        content
      }
    }
  }
`;

export default Updates;
