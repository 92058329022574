import React from "react";

const Update = ({ upd }) => {
  const { title, text, image, date, link, format } = upd.content;
  const [divCN, imgCN] =
    format === "img-left"
      ? [" sm:col-span-2 flex", "max-w-[35%] md:max-w-[20%] min-w-[10%]"]
      : ["", "block w-full"];
  return (
    <div
      className={
        "rounded-xl shadow-lg bg-white col-span-1 overflow-hidden text-sm sm:text-base leading-snug" +
        divCN
      }
    >
      {image?.filename && (
        <img
          src={image?.filename}
          alt={image?.alt ?? ""}
          className={imgCN + " object-cover"}
        />
      )}
      <div className="p-4 md:p-6 flex flex-col flex-grow">
        {link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <h2 className="as-h3 text-sm md:text-base">{title}</h2>
          </a>
        ) : (
          <h2 className="as-h3 text-sm md:text-base">{title}</h2>
        )}
        {text && <p className="mt-2">{text}</p>}
        {date && (
          <div className="flex justify-end flex-grow items-end mt-4">
            {" "}
            <p className="py-1 px-2 rounded-full bg-gray-200">
              {date.slice(0, 10)}
            </p>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default Update;
